import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, type PageProps } from 'gatsby';

import { IS_EN_LANG_ENV } from 'config/env';
import { pages } from 'constants/index';
import { Layout } from 'modules/ui';
import { BlogPostsPage, useCategories, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostsPageContext } from 'modules/blog/types';
import { useIntl } from 'modules/ui/dependencies';

interface DataProps {
    allStrapiPost: {
        nodes: BlogPost[];
    };
}

const Blog = ({ data, pageContext }: PageProps<DataProps, BlogPostsPageContext>) => {
    const categories = useCategories();

    return (
        <>
            <BlogSeo pageId={pages.BLOG} titleTemplate={IS_EN_LANG_ENV ? '%s | Ackee' : undefined} />
            <Layout>
                <BlogPostsPage
                    title={<FormattedMessage id="blog.title" />}
                    description={<FormattedMessage id="blog.description" />}
                    posts={data.allStrapiPost.nodes}
                    categories={categories}
                    basePath="/blog"
                    pageContext={pageContext}
                />
            </Layout>
        </>
    );
};
// filter: { locale: { eq: "en-US" } }
export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
        allStrapiPost(
            sort: { fields: publishedAt, order: DESC }
            limit: $limit
            skip: $skip
            filter: { locale: { eq: "cs-CZ" } }
        ) {
            nodes {
                ...BlogPost
                locale
            }
        }
    }
`;

export default Blog;
